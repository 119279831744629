import { Accordion, AccordionItem } from "../components/accordion/accordion.js"
import { graphql, useStaticQuery } from "gatsby"

import InterPoint from "../images/titles/inter-point.svg"
import Layout from "../components/layout/layout"
import { Link } from "gatsby"
import React, {useEffect, useState} from "react"
import SEO from "../components/seo.js"
import TitleIcon from "../components/decoration/title-icon.js"
import scrollTo from "../helpers/scrollToEl.js"

const Faq = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { slug: { eq: "faq" } }) {
        nodes {
          faq_list {
            faqs {
              faqAnswer
              faqQuestion
            }
          }
        }
      }
    }
  `)
  const list = data.allWpPage.nodes[0]["faq_list"].faqs
  const seo = pageContext.page.seo

  const [bindHash, setBindHash] = useState(null);

  const getIndexFromHash = location => {
    if(location.hash.length > 0){
      let tabToOpen = parseInt(location.hash.charAt(1), 10)
      return tabToOpen >= 0 && tabToOpen <= 2 ? tabToOpen : 0
    }

    return null;
  }

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <div className={"container"}>
        <div className={"page-faq"}>
          <h1 className={"title"}>
            Faq
            <TitleIcon color="#ff6b00" icon={<img src={InterPoint} alt="" />} />
          </h1>
          <p>
            Les réponses à toutes les questions que vous vous posez, c'est ici !
            <br />
            Vous ne trouvez pas votre bonheur ?
          </p>
          <Link
            to={"/contact"}
            className={"contact-link"}
            title={"Contactez-nous"}
          >
            Contactez-nous
          </Link>
          <Accordion
            additionalClass="faq-accordion"
            allowClose={true}
            defaultIndex={getIndexFromHash(location)}
            onItemClick={i =>
              setTimeout(() => {
                scrollTo(
                  document.querySelector(
                    `.accordion__item:nth-child(${i + 1})`
                  ),
                  200
                )
              }, 100)
            }
          >
            {list.map(({ faqAnswer, faqQuestion }, i) => (
              <AccordionItem label={faqQuestion} index={i} key={i} isCollapsed={true}>
                <p dangerouslySetInnerHTML={{ __html: faqAnswer }} />
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </Layout>
  )
}

export default Faq
